<template>
  <div>
    <v-row>
      <v-col>
        <div>loading: {{state.isLoading}}</div>
        <div>success: {{state.isSuccess}}</div>
        <div>data: {{someData}}</div>
        <div>error: {{state.isError}}</div>
        <div>errorMessage: {{state.errorMessage}}</div>
      </v-col>
      <v-col>
        <div>loading: {{state1.isLoading}}</div>
        <div>success: {{state1.isSuccess}}</div>
        <div>data: {{someData1}}</div>
        <div>error: {{state1.isError}}</div>
        <div>errorMessage: {{state1.errorMessage}}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {Entity_User} from "../../../EntityStoreCacheService";
import State from "@/plugins/state";
import moment from "moment";


export default {
  data: () => ({
    state: new State(),
    state1: new State(),
    someData:null,
    someData1:null
  }),
  mounted(){
    this.state.stateLoading();

    let builder = new Entity_User();
      //поля которые хотим посмотреть
      builder.selects(Entity_User.id)
          .selects(Entity_User.firstName)

          //виртуальные поля
          .virtual(Entity_User.virtual_paysCount)

          //коллектор поля
          .collect(Entity_User.collect_paysCount)

          //сортировка
          .order(Entity_User.id, true)// по возрастанию
          .order(Entity_User.firstName, false)// добавляем второе поле для сортировки, по убыванию

          //пагинация не обязательно (по умолчанию: 1, 25)
          .page(1)
          .onPage(25)

          //фильтры
          .filterAnd('user.id>? and user.firstName LIKE ?', 1, '%Тест%')
          .filterAnd('user.userProfile.birth>?', moment().subtract().subtract(25, "years"))

          //фильтр по виртуальным полям (пишется индивидуально на бэке)
          //.filterAdd(['overDate'].join('.'));

          /*
          .filterAnd('user.id<10 or user.id=?1')
          .filterAnd('user.id in (?2)')
          .filterOr('user.id=1119907')
          .filterOr('user.firstName LIKE ?3')
          .filterOr('user.userProfile.birth>?4')
          //значения фильтров
          .filterParams(
              1119901,
              [1119900, 1119901, 1119902],//можно массивы
              '%Тест%',//like
              moment().subtract().subtract(25, "years")//можно просто момент
          );

           */

    builder.request((data)=>{
          this.someData = data;
          console.log(data);
          this.state.stateSuccess();
        }, (e)=>{
          console.error(e.response.data.message);
          this.state.stateError(e);
        })

    /*
    this.state1.stateLoading();
    new Entity_Visit()
        .selects(Entity_Visit.id)
        .selects(Entity_Visit.begin)
        .selects([Entity_Visit.leadUser,Entity_ComPlace.id].join('.'))
        .selects([Entity_ComPlace.entity,Entity_ComRoom.entity, Entity_ComRoom.sid].join('.'))
        // или как вариант ниже
        .order(Entity_Visit.begin, true)
        .page(1)
        .onPage(30)

        .filterAnd('visit.comPlace.comRoom.sid=?1')
        .filterAnd('visit.begin>=?2')
        .filterParams(
            'K11',
            moment().subtract().subtract(1, "months")//можно просто момент
        )
        .request((data)=>{
          this.someData1 = data;
          console.log(data);
          this.state1.stateSuccess();
        }, (e)=>{
          console.error(e.response.data.message);
          this.state1.stateError(e);
        })

     */
  },
}
</script>